<template>
    <div class="sysInCreate">
        <el-card shadow="never">
            <el-page-header @back="goBack" />
        </el-card>
        <el-card shadow="never" style="margin-top: 8px">
            <el-form size="small">
                <el-form-item label="机构名称">
                    <el-select v-model="deptCode" filterable>
                        <el-option value="" label="请选择" />
                        <el-option v-for="dept in storeDepts" :label="dept.name" :value="dept.code" :key="dept.code" />
                    </el-select>
                </el-form-item>
            </el-form>
        </el-card>
        <el-card shadow="never" style="margin-top: 8px; text-align: left">
            <el-button type="primary" size="small" @click="handleExportTmpl" :disabled="disableBtn"
                >导出可售商品数据
            </el-button>
            <el-upload
                ref="sysInUploader"
                style="text-align: left; display: inline; margin-left: 15px"
                :action="url.importUrl"
                :multiple="false"
                :limit="1"
                :data="{ deptCode: deptCode }"
                :disabled="disableBtn"
                :on-success="handleAfterUploadSuccess"
                :on-error="handleAfterUploadError"
                :before-upload="beforeUpload"
            >
                <el-button slot="trigger" type="primary" size="small" :disabled="disableBtn || ifImportCompleteFlag"
                    >导入</el-button
                >
                <el-button
                    style="margin-left: 15px"
                    type="primary"
                    size="small"
                    @click="handleDownloadErrorData"
                    :disabled="disableBtn || !hasErrorData"
                    >下载导入失败的数据
                </el-button>
            </el-upload>
        </el-card>
    </div>
</template>

<script>
import Utils from 'js/Util';
import UrlUtils from 'js/UrlUtils';
import { Message } from 'element-ui';
import { DeptTypeEnum } from 'js/DeptCommon';

export default {
    name: 'SysInCreate',
    data() {
        return {
            deptCode: '',
            hasErrorData: false,
            ifImportCompleteFlag: false,
            storeDepts: [],
            tableData: [],
            url: {
                exportTmpl: '/purchase/sysIn/exportGoodsAndReadMe',
                importUrl: this.$proxyApiHostUrlSuffix + '/purchase/sysIn/importStore',
                errorData: '/purchase/sysIn/exportCanNotImportData',
                checkError: '/purchase/sysIn/checkIfExistCanNotImportData',
            },
        };
    },
    mounted() {
        (async () => {
            this.storeDepts = await this.$efApi.deptApi.managedDeptsByDeptType(DeptTypeEnum.STORE);
            // await this.checkError();
        })();
    },
    computed: {
        disableBtn() {
            return Utils.isEmpty(this.deptCode) && this.hasPrivilege('biz.price.wholeSale.query');
        },
    },
    methods: {
        async checkError() {
            const _this = this;
            if (!this.deptCode) {
                _this.hasErrorData = false;
                return;
            }
            await UrlUtils.QueryRemote(this, this.url.checkError + '?deptCode=' + this.deptCode, (data) => {
                _this.hasErrorData = data;
                return;
            });
        },
        afterClickImportButton() {
            this.disableBtn = false;
        },
        beforeUpload(file) {
            if (!this.hasPrivilege('biz.price.wholeSale.query')) {
                this.$message.error('导入模板需要查询批发价权限');
                return false;
            }
            this.ifImportCompleteFlag = true;
            if (!file.name.endsWith('.xls')) {
                this.$message.error('请使用下载的模板进行导入操作,请勿任意改动模板名称与格式');
                this.ifImportCompleteFlag = false;
                return false;
            }
        },
        async handleAfterUploadSuccess(response) {
            UrlUtils.QueryRemote(this, this.url.checkError + '?deptCode=' + this.deptCode, (data) => {
                this.hasErrorData = data;
                if (!this.hasErrorData) {
                    this.ifImportCompleteFlag = true;
                    Message({
                        message: '导入成功',
                        type: 'success',
                        duration: 1000,
                    });
                    setTimeout(() => {
                        this.goBackAndReload();
                    }, 2000);
                } else {
                    this.ifImportCompleteFlag = false;
                    this.$refs.sysInUploader.clearFiles();
                    this.handleDownloadErrorData();
                }
            });
        },
        handleAfterUploadError(response) {
            let errMsg = this.isJsonString(response.message) ? JSON.parse(response.message).message : null;
            this.ifImportCompleteFlag = false;
            if (Utils.isEmpty(errMsg)) {
                errMsg = response.message;
            }
            Message({
                message: errMsg,
                type: 'error',
                duration: 3 * 1000,
            });
            this.$refs.sysInUploader.clearFiles();
            return false;
        },
        handleExportTmpl() {
            if (Utils.isEmpty(this.deptCode)) {
                return;
            }
            if (!this.hasPrivilege('biz.price.wholeSale.query')) {
                this.$message.error('导出模板需要查询批发价权限');
                return;
            }
            const _param = { deptCode: this.deptCode };
            UrlUtils.Export(this, '初始化商品', this.url.exportTmpl, _param);
        },
        handleDownloadErrorData() {
            this.$confirm(
                '<span>有部分数据导入失败,请下载导入失败的数据文件,将失效数据处理好后再次导入</span>' +
                    '</br><span>仅保留最后一次导入失败的数据,且仅保留10分钟,请及时下载</span></br><span></span>',
                '提示',
                {
                    dangerouslyUseHTMLString: true,
                    confirmButtonText: '下载导入失败数据',
                    cancelButtonText: '取消',
                }
            )
                .then(() => {
                    this.download();
                })
                .catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消下载失败模板',
                    });
                });
        },
        download() {
            if (Utils.isEmpty(this.deptCode)) {
                return;
            }
            const _param = { deptCode: this.deptCode };
            UrlUtils.Export(this, '导入失败的数据', this.url.errorData, _param);
        },
        isJsonString(str) {
            try {
                if (typeof JSON.parse(str) == 'object') {
                    return true;
                }
            } catch (e) {
                throw new Error();
            }
            return false;
        },
    },
};
</script>
